import React from 'react';
import seapig, { OPTIONAL, OPTIONALS, REQUIRED, REQUIREDS } from 'seapig';
import MediaQuery from 'react-responsive';

export const HeaderRow = ({children}) =>
  <header>
    <div className="row">
      <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
      {/* <div className="bb-ns pb2 b--white-30 col-xs-10 col-sm-10 col-md-8 col-lg-8 mt2 mb2"> */}
      <div className="pb2 b--white-30 col-xs-10 col-sm-10 col-md-8 col-lg-8 mt2 mb2">
        {children}
      </div>
      <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
    </div>
  </header>

export const GridRow = ({children}) =>
  <div className="row pt3">
    <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
    <div className="col-xs-10 col-sm-10 col-md-8 col-lg-8">
      {children}
    </div>
    <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
  </div>

export const WideGridRow = ({children}) =>
  <div className="row pt3">
    <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1"></div>
    <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10">
      {children}
    </div>
    <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1"></div>
  </div>

export const GridRow2 = ({children}) =>
  <GridRow>
    <div className="row center-xs center-sm center-md center-lg pb4">{children}</div>
  </GridRow>

export const ActionRow = ({ children }) => {
  const {
    proseChildren,
    actionChildren,
  } = seapig(children, {
    prose: REQUIRED,
    action: OPTIONAL,
  });

  return (
    <div className="row top-md">
      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-7">
        {proseChildren}
      </div>
      { actionChildren && actionChildren.length > 0 &&
        <>
          <MediaQuery minDeviceWidth={701}>
            <div className="col-sm-1 col-md-1 col-lg-1"></div>
            <div className="pt3-m pb2-m col-sm-5 col-md-5 col-lg-4">
              {actionChildren}
            </div>
          </MediaQuery>
          <MediaQuery maxDeviceWidth={700}>
            <div className="pt3 pb2 col-xs-12 col-sm-4">
              {actionChildren}
            </div>
          </MediaQuery>
        </>
      }
    </div>
  )
}

export const DetailRow = ({children}) => {
  const {
    segmentChildren,
    proseChildren,
    actionChildren,
  } = seapig(children, {
    segment: REQUIRED,
    prose: REQUIRED,
    action: OPTIONALS,
  });
  return (
    <GridRow>
      <div className='row bt pt3 b--light-gray'>
        <div className="col-xs-4 col-sm-2 col-md-2 col-lg-2">{segmentChildren}</div>
        <div className="col-xs-7 col-sm-9 col-md-10 col-lg-10">
        <ActionRow>
          <div prose='true' className="">{proseChildren}</div>
          { actionChildren && actionChildren.length > 0 && <div action='true' className="">{actionChildren}</div> }
        </ActionRow>
        </div>
      </div>
    </GridRow>
  );
}

export const BlockChoiceRow = ({children}) => {
  const {
    choiceChildren,
  } = seapig(children, {
    choice: REQUIREDS,
  });
  return (
    <GridRow>
      <div className="row">
        <div className="col-sm-2 col-md-2 col-lg-2"></div>
        { choiceChildren.map( (child, idx) => (
          <div key={idx} className="col-xs-11 col-sm-4 col-md-4 col-lg-4 mb4">
            {child}
          </div>
        ))}
      </div>
    </GridRow>
  );
}
