import { SettingsQuery } from '../queries/SettingsQuery';

export const accountId = (client) => {
  
  try {

    const { profile } = client.readQuery({
      query: SettingsQuery,
    });

    return { account: profile.accounts[0].id };

  } catch(e) {

    return { error: e}
  }

}
export const retryAccountId = async (client) => {
  
  const request = {
    query: SettingsQuery,
  };

  const {data, error} = await client.query(request)
  .then(({data, error}) => ({data, error}))
  .catch((error) => ({ error }));
  
  if (error) {
    return {error};
  } else {
    return { account: data.profile.accounts[0].id };
  }
}