import React from 'react';

import '@trendmicro/react-dropdown/dist/react-dropdown.css';
import Dropdown, {
  MenuItem,
} from '@trendmicro/react-dropdown';

import { navigate } from 'gatsby';

import './buttons';

import styled from 'styled-components';

export const ProductDropdown = (props) =>
  <Dropdown autoOpen={true}>
    <CustomToggle btnStyle="link" noCaret={true}>Product</CustomToggle>
    <Product onSelect={navigate} />
  </Dropdown>

export const SupportDropdown = (props) =>
  <Dropdown autoOpen={true}>
    <CustomToggle btnStyle="link" noCaret={true}>Support</CustomToggle>
    <Support onSelect={navigate} />
  </Dropdown>

const Support = (props) =>
  <CustomDropdownMenu {...props}>
    <CustomMenuItem eventKey='/blog'>Blog</CustomMenuItem>
    <CustomMenuItem eventKey='/support'>Support</CustomMenuItem>
  </CustomDropdownMenu>

const Product = (props) =>
  <CustomDropdownMenu {...props}>
    <CustomMenuItem eventKey='/features'>Features</CustomMenuItem>
    <CustomMenuItem eventKey='/discover'>Discover</CustomMenuItem>
    <CustomMenuItem eventKey='/using'>Solutions</CustomMenuItem>
  </CustomDropdownMenu>

const CustomDropdownMenu = styled(Dropdown.Menu)`
&& {
  font-size: 18px;
  border: 0.5px solid #bbbbbb;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
`;
CustomDropdownMenu.propTypes = Dropdown.Menu.propTypes;
CustomDropdownMenu.defaultProps = Dropdown.Menu.defaultProps;

const CustomToggle = styled(Dropdown.Toggle)`
&& {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-transform: uppercase;
  &:hover {
    color: #555;
    transform: scale(1.05);
  }
}
`;
CustomToggle.propTypes = Dropdown.Toggle.propTypes;
CustomToggle.defaultProps = Dropdown.Toggle.defaultProps;

const CustomMenuItem = styled(MenuItem)`
&& {
}
`;
CustomMenuItem.propTypes = MenuItem.propTypes;
CustomMenuItem.defaultProps = MenuItem.defaultProps;