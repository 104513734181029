import React, { useState } from 'react';
import { Link }  from 'gatsby';
import Modal from 'react-modal';

import { Container } from 'constate';

import { IoIosCloseCircleOutline } from 'react-icons/io';

import { authEffects, authSelectors } from '../app/effects/auth';
import { HeaderRow } from '../components/widgets/grid';

import SwsmLogo from '../pages/svg/logos/swsm_logo.svg';
import TelephoneIcon from '../pages/svg/icons/np_telephone_674287_000000.svg';

import '../pages/css/modal.css';

const styles = {
  content : {
    top:    '10%',
    left:   '5%',
    right:  '5%',
    bottom: 'auto',
    padding: '0px',
    borderRadius: '7px',
    outline: 'none',
    border: '1px solid #ffd400'
  },
  overlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: 999
  }
};

const noAuthRoutes = ({login, toggleMenu}) => [
  { label: 'Home', path: '/', onClick: toggleMenu },
  // { label: 'Download', path: '/download', onClick: toggleMenu },
  { label: 'Features', path: '/features', onClick: toggleMenu },
  { label: 'Discover', path: '/discover', onClick: toggleMenu },
  { label: 'Solutions', path: '/using', onClick: toggleMenu },
  { label: 'Pricing', path: '/pricing', onClick: toggleMenu },
  { label: 'Blog', path: '/blog', onClick: toggleMenu },
  // { label: 'Sign Up', path: '/signup', onClick: toggleMenu },
  // { label: 'Log In', onClick: login }
];

const authRoutes = ({logout, toggleMenu}) => [
  { label: 'Dashboard', path: '/app/dashboard', onClick: toggleMenu },
  { label: 'Billing', path: '/app/billing', onClick: toggleMenu },
  { label: 'Sign Out', onClick: logout }
];

const bootstrapRoutes = ({logout}) => [
  { label: 'Sign Out', onClick: logout }
];

const Menu = ({visible, toggleMenu, items}) => (
  <div>
    <Modal isOpen={visible}
           shouldCloseOnOverlayClick={true}
           onRequestClose={toggleMenu}
           style={styles}>

      <div className="gradient-yellow pa2">

        <div className="flex flex-row-reverse mr2 mt2">

          <Link to="#" onClick={toggleMenu}>
            <IoIosCloseCircleOutline className="h2 w2 black-50" />
          </Link>

        </div>

        {
          items.map(({ onClick, path, label}, idx) =>
            <div className='ma3 menu-item' key={idx}>
              { path &&
                <Link onClick={onClick} className='link near-black hover-mid-gray' to={path}>
                  <h4>{label}</h4>
                </Link>
              }
              {
                !path &&
                <a className="link near-black hover-mid-gray" href="#0" onClick={onClick}>
                  <h4>{label}</h4>
                </a>
              }
            </div>
          )
        }

      </div>

    </Modal>
  </div>
)

const Logo = () =>
  <div className="w2 h2">
    <Link to={"/"}>
      <SwsmLogo />
    </Link>
  </div>

const MenuStructure = ({ isAuthenticated, bootstrapAuthenticated, login, logout }) => {

  const [visible, setVisible] = useState(false);
  const toggleMenu = () => setVisible(!visible)

  var items;

  if (isAuthenticated()) {
    items = authRoutes({logout, toggleMenu});
  } else if (bootstrapAuthenticated()) {
    items = bootstrapRoutes({logout, toggleMenu});
  } else {
    items = noAuthRoutes({login, logout, toggleMenu});
  }

  return (
    <div>
      <div className="row graph-white pt1 bb b--black-20">
        <div className="col-xs-12 center-xs">
          <a href="tel:+441865950200" className="link mid-gray dib">
            <div className="flex items-center">
              <TelephoneIcon className="h1 w1" />
              <div className="ml2">01865 950 200</div>
            </div>
          </a>
        </div>

      </div>
      <div>
        <Menu items={items}
              visible={visible}
              toggleMenu={toggleMenu}
              login={login}
              logout={logout} />

        <div className="row pt2 pb2 pr2 pl2">

          <div className="col-xs-10 flex items-center">

            <Logo />
            <Link to={"/"}>
              <div className="ml2 ttu f4 fw6 link near-black hover-mid-gray grow dib">SwitchSmith</div>
            </Link>

          </div>

          <div className="col-xs-2 flex items-center justify-end">
            <div className='menu-item'>
              <a className="ba br2 ph1 pv1 dib link near-black hover-mid-gray" href="#0" onClick={toggleMenu}>Menu</a>
            </div>
          </div>

        </div>

      </div>
    </div>
  );
}

export const AppMenu = () => (
  <Container
    context='primary'
    effects={authEffects}
    selectors={authSelectors}>
    {(props) => (
      <MenuStructure {...props}/>
    )}
  </Container>
);
