import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { useStaticQuery, graphql, Link } from 'gatsby';

import seapig, { OPTIONAL } from 'seapig';

import 'tachyons';
import 'flexboxgrid';

import { Container } from 'constate';

import { authEffects, authSelectors } from '../app/effects/auth';

import { AppMenu } from '../components/AppMenu';
import { WebMenu } from '../components/WebMenu';

import { library, config, dom } from '@fortawesome/fontawesome-svg-core';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';

//require('@fortawesome/fontawesome-pro/js/duotone-min.js');

config.autoAddCss = false;
library.add(fad, fal, far);

const Layout = ({ children }) => {

  const {
    headlineChildren,
    rest
  } = seapig(children, {
    headline: OPTIONAL,
  });

  const data = useStaticQuery(query);
  const { site } = data;

  const { siteMetadata } = site;
  const { quip, } = siteMetadata;

  return (
    <>
      <Helmet title={`${quip} | SwitchSmith`}>

        {/* <script>{`
          var els = document.getElementsByClassName("noscript");
          for (var i = 0; i < els.length; i++) {
            els[i].style.display = 'none';
          }
        `}
        </script> */}

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@switch_sm" />
        <meta name="twitter:creator" content="@switch_sm" />
        <meta name="twitter:image" content="https://switchsmith.com/twitter-card-1.png" />

        <meta name="description" content="Use push notifications to handle your inbound calls. With SwitchSmith, your ring group activity in visualized in one place in real time." />
        <meta name="og:title" content={quip} />
        <meta name="og:description" content="Use push notifications to handle your inbound calls. With SwitchSmith, your ring group activity in visualized in one place in real time." />
        <meta property="og:type" content="website" />>
        <meta property="og:url" content="https://switchsmith.com" />>
        <meta property="og:image" content="/180X180.png" />
        <meta property="og:site_name" content="SwitchSmith" />

        <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1,minimum-scale=1"></meta>
        <meta name="apple-itunes-app" content="app-id=1194663008"></meta>

        <style>{`
          ${dom.css()}
          .bg-yellow {
            background-color: #ffd400;
          }
          .gradient-yellow { background: linear-gradient( 0deg, rgba(255, 212, 0, 1) 20%, rgba(255, 212, 1, .5) 95%); }
          .graph-white {
            background-color: #f7f7f7;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%2355505c' fill-opacity='0.05'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          }
          .list {
            list-style-type: square;
          }
          .flex-basis-20 {
            flex-basis: 20%;
          }
          @media only screen and (min-width: 22em) {
            .col-gte-xs {
              display: none;
            }
          }
          @media only screen and (max-width: 22em) {
            .col-lt-xs {
              display: none;
            }
          }
          @media only screen and (max-width: 48em) {
            .col-xs-0 {
              display: none;
            }
          }
          @media only screen and (min-width: 48em) and (max-width: 64em) {
            .col-sm-0 {
              display: none;
            }
          }
          @media only screen and (min-width: 64em) and (max-width: 75em) {
            .col-md-0 {
              display: none;
            }
          }
          @media only screen and (min-width: 75em) {
            .col-lg-0 {
              display: none;
            }
          }
          *:focus {
            outline: none;
          }
          .fslightbox-toolbar-button:nth-child(1) {
            display: none;
          }
          html {
            font-family: sans-serif;
          }
          .h5-3 { height: 1.66rem; }
          .w5-3 { width: 1.66rem; }
          body {
            overflow: hidden;
          }
        `}</style>

        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`/favicon-32x32.png`}
        />
      </Helmet>

      <div className="gradient-yellow">

        <div className="row">
          <div className="col-xs-0 col-sm-0 col-md-0 col-lg-12"><WebMenu /></div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-0"><AppMenu /></div>
        </div>

      </div>

      <div>
        { headlineChildren }
      </div>

      <div>
        <Container
          context='primary'
          effects={authEffects}
          selectors={authSelectors}
          >
          {({ isAuthenticated }) => {
            const auth = isAuthenticated();
            return (
              <div>
                {rest}
              </div>
            );
          }}
        </Container>
      </div>
      <footer className="bg-yellow">

        <div className="row pt4 pb4">
          <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>

          <div className="col-xs-5 col-sm-5 col-md-2 col-lg-2">

            <div>

              {/* <div className='pv1'>
                <Link to={'/signup'}
                  className="link near-black hover-mid-gray">
                  <h6>Sign Up</h6>
                </Link>
              </div> */}

              {/* <div className='pv1'>
                <Link to={'/app/dashboard'}
                  className="link near-black hover-mid-gray">
                  <h6>Sign In</h6>
                </Link>
              </div> */}

              <div className='pv1'>
                <Link to={'/using'}
                  className="link near-black hover-mid-gray">
                  <h6>Solutions</h6>
                </Link>
              </div>

              <div className='pv1'>
                <Link to={'/flow'}
                  className="link near-black hover-mid-gray">
                  <h6>Flow</h6>
                </Link>
              </div>

              {/* <div className='pv1'>
                <Link to={'/download'}
                  className="link near-black hover-mid-gray">
                  <h6>Download</h6>
                </Link>
              </div> */}

              <div className='pv1'>
                <Link to={'/discover'}
                  className="link near-black hover-mid-gray">
                  <h6>Discover</h6>
                </Link>
              </div>

              <div className='pv1'>
                <Link to={'/blog'}
                  className="link near-black hover-mid-gray">
                  <h6>Blog</h6>
                </Link>
              </div>

              <div className='pv1'>
                <Link to={'/pricing'}
                  className="link near-black hover-mid-gray">
                  <h6>Pricing</h6>
                </Link>
              </div>

            </div>

          </div>

          <div className="col-xs-5 col-sm-5 col-md-2 col-lg-2">

            <div className='pv1'>
              <Link to={'/support'}
                className="link near-black hover-mid-gray">
                <h6>Support</h6>
              </Link>
            </div>

            {/* <div className='pv1'>
              <Link to={'/trial'}
                className="link near-black hover-mid-gray">
                <h6>Trial</h6>
              </Link>
            </div> */}

            <div className='pv1'>
              <Link to={'/billing'}
                className="link near-black hover-mid-gray">
                <h6>Billing</h6>
              </Link>
            </div>

            {data.allMarkdownRemark.edges.map(({ node }) =>
              // TODO the key should be node.id, but ATM this value is undefined
              <div className='pv1 flex flex-row' key={node.fields.slug}>
                <Link to={node.fields.slug}
                  className="link near-black hover-mid-gray">
                  <h6>{node.frontmatter.title}</h6>
                </Link>
              </div>
            )}
          </div>

        </div>

      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        quip
      }
    }
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/(site).*/"}},
      sort: { fields: [frontmatter___priority], order: ASC }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            priority
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;