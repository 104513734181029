import gql from 'graphql-tag';

export const SettingsQuery = gql`
  query Settings {
    profile {
      user {
        id
        name
        number
      }
      accounts {
        id
        owner_id
        name
        billing {
          plan
          state
          start_date
          end_date
        }
      }
    }
    status {
      notify {
        state
        since
      }
    }
  }
`;