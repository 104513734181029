import { navigate } from '@reach/router';
import { accountId, retryAccountId } from '../graph/cache/account';
import { join } from 'path';

const joinUrl = `${process.env.GATSBY_SITE_URL}/app/join/`;

export const authEffects = {
  login: () => ({state, setState}) => {
    const { auth } = state;
    auth.login();
  },
  logout: () => ({state, setState}) => {
    const { auth } = state;
    auth.logout();
  },
  handleAuth: ({location}, destination) => ({ state, setState }) => {
    const { auth } = state;
    if (/access_token|id_token|error/.test(location.hash)) {
      //console.log('handling auth: ', destination, auth);
      auth.handleAuthentication(destination);
    } else {
      //console.error('Location hash no token: ', location);
      // bugsnag?
    }
  },
  register: () => async ({state, setState}) => {
    
    const { auth, bootstrapAuth, client } = state;

    if (bootstrapAuth) {
      let { account, error } = accountId(client);

      var flag = (account == undefined);

      if (error) {
        ({ account, error } = await retryAccountId(client));
        flag = (account == undefined);
      }

      if (flag) {
        setState({refreshAttempted: true});
        navigate('/app/join');
      } else {
        auth.login(joinUrl);  
      }
    } else {
      auth.login(joinUrl);
    }
  },
};

export const authSelectors = {
  isAuthenticated: () => state => (state.authenticated),
  bootstrapAuthenticated: () => state => (state.bootstrapAuth)
};