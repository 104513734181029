import React from 'react';
import { Link } from 'gatsby';

import { Container } from 'constate';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { authEffects, authSelectors } from '../app/effects/auth';

import { HeaderRow } from '../components/widgets/grid';

import SwsmLogo from '../pages/svg/logos/swsm_logo.svg';
import TelephoneIcon from '../pages/svg/icons/np_telephone_674287_000000.svg';

import {
  ProductDropdown,
} from './widgets/dropdown/menu';

const WebMenuStructure = ({isAuthenticated, bootstrapAuthenticated, logout, login}) => {

  if (isAuthenticated() || bootstrapAuthenticated()) {
    return (
      <HeaderRow>
        <Menu onLogoutClick={logout}/>
      </HeaderRow>
    )
  } else {
    return (
      <Menu onLoginClick={login}/>
    );
  }
}

const Logo = () =>
  <div className="">
    <div className="">
      <div className="w2 h2">
        <Link to={"/"} className="grow dib w-100">
          <SwsmLogo/>
        </Link>
      </div>
    </div>
  </div>

const DirectLink = ({idx, to, link, onClick}) =>
  <Link to={to} className="f5 ttu fw6 link near-black hover-mid-gray grow dib" onClick={onClick}>
    {link}
  </Link>

const CallUs = () =>
  <a href="tel:+441865950200" className="f5 fw6 link near-black hover-mid-gray grow dib">
    <div className="flex items-center">
      <FontAwesomeIcon className="fa-lg" icon={['fal', 'phone-rotary']}/>
      {/* <TelephoneIcon className="h1 w1"/> */}
      <div className="ml2">01865 950 200</div>
    </div>
  </a>

const Menu = ({ onLoginClick, onLogoutClick }) =>
  <div className="row pt3 pb3">

    <div className="col-lg-2 flex items-center">

      <div className="pl4 pr3">
      <Logo />
      </div>

      <Link to={"/"} className="link near-black hover-mid-gray grow dib">
        <div className="f3 fw6 ttu">SwitchSmith</div>
      </Link>
    </div>

    <div className="col-sm-4 col-md-1 col-lg-4"></div>

    <div className="col-xs-12 col-sm-1 col-md-5 col-lg-6">
      <div className="flex items-center justify-between mr4">
      { onLoginClick &&
        <>
          <ProductDropdown />
          <DirectLink to='/pricing' link='Pricing' />
          {/* <DirectLink to='/download' link='Download'/> */}
          <DirectLink to='/blog' link='Blog'/>
        </>
      }
      <CallUs />
      {/* { onLoginClick &&
        <>
          <DirectLink onClick={onLoginClick} link='Log In' />
        </>
      }
      { onLogoutClick &&
        <>
          <DirectLink to='/app/dashboard' link='Dashboard' />
          <DirectLink to='/app/billing' link='Billing' />
          <DirectLink onClick={onLogoutClick} link='Log Out' />
        </> */}
      </div>
    </div>




  </div>

export const WebMenu = () => (
  <Container
    context='primary'
    effects={authEffects}
    selectors={authSelectors}>
    {(props) => (
      <WebMenuStructure {...props}/>
    )}
  </Container>
);


